<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="350">
    <v-card>
      <v-card-title class="headline error-text">
        Permanently Delete
      </v-card-title>
      <v-card-text>
        <strong>
          There are exactly
          <span class="red--text font-weight-bold">{{ link.users_count }}</span>
          no. of users who are using this social media.
          <br />
          <br />
          Are you sure you want to continue?
        </strong>
      </v-card-text>

      <v-card-actions class="pb-4">
        <div class="flex-grow-1"></div>
        <v-btn color="error" :disabled="loading" text @click="$emit('cancel')">
          Cancel
        </v-btn>
        <v-btn color="primary" :loading="loading" @click="$emit('confirm')">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,

    link: {
      type: Object,
      required: true,
    },

    loading: Boolean,
  },
}
</script>
