<template>
  <div class="groups--content mt-10 px-4">
    <v-row class="mt-9">
      <v-col sm="5">
        <v-row>
          <v-col sm="12">
            <div class="d-flex flex-row align-stretch avatar-wrapper">
              <div>
                <v-img
                  v-if="imageData"
                  :src="imageData"
                  height="160"
                  width="160"
                  aspect-ratio="1/1"
                  cover
                  rounded
                />
                <v-img
                  v-else
                  src="https://image-placeholder.com/images/actual-size/160x160.png"
                  height="160"
                  width="160"
                  aspect-ratio="1/1"
                  cover
                  rounded
                />
              </div>

              <div class="d-flex flex-column justify-space-around ml-6">
                <v-btn
                  text
                  class="text-capitalize font-weight-bold text-light-blue"
                  @click="$refs.filePicker.click()"
                >
                  <img src="@/assets/icons/icon-image.svg" class="mr-2" alt />
                  Upload Logo
                </v-btn>

                <input
                  ref="filePicker"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="onFileChange"
                />
              </div>
            </div>
          </v-col>

          <v-col sm="12">
            <v-text-field
              v-model="form.name"
              label="Social Media Link Name"
              :error-messages="form.$getError('name')"
              :hide-details="!form.$hasError('name')"
              clearable
              outlined
              flat
            />
          </v-col>

          <v-col sm="12">
            <v-btn
              v-if="addNew"
              color="primary"
              :loading="form.$busy"
              :disabled="!validForm"
              x-large
              @click="addNewSocialLink()"
            >
              Add New
            </v-btn>
            <div class="d-flex" v-else-if="editItem">
              <v-btn
                color="primary"
                :loading="form.$busy"
                :disabled="!validForm"
                class="mr-2"
                x-large
                @click="updateSocialLink"
              >
                Update
              </v-btn>
              <v-btn :loading="form.$busy" x-large @click="handleCancel">
                Cancel
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="pl-10" sm="7">
        <v-card>
          <v-card-text>
            <v-row dense>
              <v-col sm="12">
                <v-alert border="left" dense text type="info" prominent>
                  To sort the items, simply drag and drop them into your desired
                  order. Click and hold
                  <v-icon color="black">
                    {{ icons.drag }}
                  </v-icon>
                  of the item you wish to move, and then drag it to the desired
                  position.
                </v-alert>
              </v-col>

              <v-col class="text-right" sm="12">
                <template v-if="edit">
                  <v-btn
                    color="success"
                    :loading="savingChanges"
                    :disabled="hasChanges"
                    @click="saveChanges()"
                  >
                    Save Changes
                  </v-btn>

                  <v-btn
                    class="ml-3"
                    color="error"
                    :disabled="savingChanges"
                    @click="handleCancel"
                  >
                    <v-icon class="ml-n2">{{ icons.close }}</v-icon>
                    <span class="ml-1 mt-n1">Cancel Edit</span>
                  </v-btn>
                </template>

                <v-btn
                  v-if="!edit"
                  :disabled="links.length <= 1"
                  color="primary"
                  @click="edit = true"
                >
                  <v-icon class="ml-n2">{{ icons.edit }}</v-icon>
                  <span class="ml-1 mt-n1"> Edit & Sort List </span>
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="draggable-list-container mt-4" dense>
              <draggable
                tag="div"
                class="col col-12"
                :list="links"
                :animation="180"
                :disabled="!edit"
                :force-fallback="true"
                fallback-class="chosen"
                ghost-class="moving-ghost-list"
                handle=".v-icon"
              >
                <template v-for="link in links">
                  <ListItem
                    :key="link.id"
                    :link="link"
                    :edit="edit"
                    @item:editing="handleEditItem(link)"
                    @item:deleted="handleDeletedItem($event)"
                  />
                </template>
              </draggable>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import ListItem from './components/ListItem'
import SocialLink from '@/models/SocialLink'
import Draggable from 'vuedraggable'
import Form from '@/utils/form'

import { map, cloneDeep, isEqual, findIndex } from 'lodash'
import {
  mdiClose,
  mdiDragVertical,
  mdiPencilOutline,
  mdiPlus,
  mdiTrashCanOutline,
} from '@mdi/js'

export default {
  name: 'SociaMediaLinks',

  components: {
    Draggable,
    ListItem,
  },

  data() {
    return {
      addNew: true,
      edit: false,
      savingChanges: false,

      imageData: null,

      form: new Form({
        id: null,
        logo: null,
        name: null,
      }),

      copiedLinks: [],
      links: [],

      icons: {
        plus: mdiPlus,
        edit: mdiPencilOutline,
        close: mdiClose,
        drag: mdiDragVertical,
        delete: mdiTrashCanOutline,
      },

      snackbar: {
        show: false,
        color: '',
        message: '',
      },
    }
  },

  computed: {
    hasChanges() {
      return isEqual(this.links, this.copiedLinks)
    },
    validForm() {
      return (
        (this.form.name && this.form.id) || (this.form.logo && this.form.name)
      )
    },
  },

  created() {
    this.fetchSocialLinks()
  },

  methods: {
    async fetchSocialLinks() {
      const { data } = await SocialLink.get()

      this.setAndCloneData(data)
    },

    setAndCloneData(data) {
      this.links = data
      this.copiedLinks = cloneDeep(data)
    },

    onFileChange(value) {
      const file = value.target.files[0]

      const reader = new FileReader()

      reader.readAsDataURL(file)

      reader.onload = async (e) => {
        this.imageData = e.target.result
        this.form.logo = file
      }
    },

    async addNewSocialLink() {
      this.form.$busy = true
      this.form.$clearErrors()

      try {
        const formData = new FormData()
        formData.append('logo', this.form.logo)
        formData.append('name', this.form.name)

        const { data } = await this.$api.post('social-links', formData)

        setTimeout(() => {
          this.links.push(new SocialLink(data.data))
          this.setAndCloneData(this.links)
          this.form.$reset()
          this.form.$busy = false
          this.imageData = null

          this.snackbar = {
            show: true,
            color: 'success',
            message: 'Social link added successfully!',
          }
        }, 800)
      } catch (err) {
        this.form.$busy = false

        if (err.response.status === 422) {
          this.form.$setErrors(err.response.data.errors)
        }
      }
    },

    async updateSocialLink() {
      this.form.$busy = true
      this.form.$clearErrors()

      try {
        const formData = new FormData()
        if (this.form.logo) {
          formData.append('logo', this.form.logo)
        }
        formData.append('name', this.form.name)
        formData.append('_method', 'PUT')

        const { data } = await this.$api.post(
          `social-links/${this.form.id}`,
          formData
        )

        setTimeout(() => {
          const index = this.links.findIndex((item) => item.id === data.data.id)
          this.links[index] = data.data

          this.addNew = true
          this.edit = false
          this.editItem = false
          this.form.$reset()
          this.form.$busy = false
          this.imageData = null

          this.snackbar = {
            show: true,
            color: 'success',
            message: 'Social link updated successfully!',
          }
        }, 800)
      } catch (err) {
        this.form.$busy = false

        if (err.response.status === 422) {
          this.form.$setErrors(err.response.data.errors)
        }
      }
    },

    async saveChanges() {
      this.savingChanges = true

      try {
        const { data } = await this.$api.post('social-links', {
          ids: map(this.links, 'id'),
        })

        setTimeout(() => {
          this.setAndCloneData(data.data)
          this.addNew = true
          this.edit = false
          this.editItem = false
          this.savingChanges = false
        }, 800)
      } catch (err) {
        this.savingChanges = false
      }
    },

    handleCancel() {
      this.addNew = true
      this.edit = false
      this.editItem = false

      this.imageData = null
      this.form.$reset()
    },

    handleEditItem(val) {
      this.addNew = false
      this.edit = false
      this.editItem = true
      this.form.$reset()
      this.form.id = val.id
      this.form.name = val.name
      this.imageData = val.icon.url
    },

    handleDeletedItem({ id }) {
      this.form.$reset()
      this.addNew = true
      this.edit = false
      this.editItem = false

      const _index = findIndex(this.links, { id })

      this.links.splice(_index, 1)
    },
  },
}
</script>

<style lang="scss">
.draggable-list-container {
  .moving-ghost-list {
    opacity: 0.1 !important;
    color: transparent !important;
    border: 3px dashed var(--v-primary-base) !important;
    background-color: var(--v-grey-base) !important;
  }

  .sortable-drag {
    opacity: 1 !important;
    cursor: pointer !important;
    border-radius: 4px !important;
    background-color: white !important;
    border: 1px solid var(--v-grey-base) !important;

    .v-divider {
      visibility: hidden !important;
    }
  }
}
</style>
