<template>
  <v-card class="mb-2" :key="link.id" outlined>
    <v-card-text>
      <div class="d-flex align-center">
        <v-icon v-if="edit" class="ml-n2 mr-2">
          {{ icons.drag }}
        </v-icon>

        <v-img max-height="30" max-width="30" :src="link.icon.thumb_url" />

        <div class="ml-5">
          {{ link.name }}
        </div>

        <v-spacer />

        <v-btn v-if="!deleting" text icon @click="$emit('item:editing')">
          <v-icon>{{ icons.edit }}</v-icon>
        </v-btn>

        <v-btn
          color="error"
          :loading="deleting"
          text
          icon
          @click="deleteSocialLink()"
        >
          <v-icon>{{ icons.delete }}</v-icon>
        </v-btn>
      </div>
    </v-card-text>

    <ConfirmModal
      v-model="showForceDeleteModal"
      :link="link"
      :loading="deleting"
      @cancel="showForceDeleteModal = false"
      @confirm="deleteSocialLink(true)"
    />
  </v-card>
</template>

<script>
import ConfirmModal from './DeleteItemModal'

import { mdiDragVertical, mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js'

export default {
  name: 'SocialMediaLinksListItem',

  props: {
    link: {
      type: Object,
      required: true,
    },

    edit: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ConfirmModal,
  },

  data() {
    return {
      showForceDeleteModal: false,
      deleting: false,

      icons: {
        edit: mdiPencilOutline,
        drag: mdiDragVertical,
        delete: mdiTrashCanOutline,
      },
    }
  },

  methods: {
    async deleteSocialLink(force = false) {
      this.deleting = true

      try {
        await this.$api.delete(`social-links/${this.link.id}`, {
          data: { force },
        })

        this.deleting = false
        this.$emit('item:deleted', this.link)
      } catch (err) {
        this.deleting = false

        if (err.response.status === 403) {
          this.showForceDeleteModal = true
        }
      }
    },
  },
}
</script>
